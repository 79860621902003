<template>
  <div class="universal-select">
    <span v-if="title" class="universal-select__title">{{ title }}</span>
    <a-select
      :allow-clear="allowClear"
      :value="value"
      size="large"
      :class="{ 'transparent-select' : readonly }"
      :showArrow="!readonly"
      :disabled="disabled"
      :loading="loading"
      :show-search="showSearch"
      :filter-option="false"
      :get-popup-container="getPopupContainer"
      @search="handleSearch"
      @change="handleChange"
    >
      <template v-if="placeholder" #placeholder>
        <span style="color: #a1a1c2;">{{ placeholder }}</span>
      </template>
      <a-select-option
        v-for="(option, index) in filteredOptions"
        :key="option.value"
        class="universal-select__option"
        :class="{'universal-select__option-highlight' : index === 0 && value !== option.value && highlightFirst}"
      >
        <div class="universal-select__option__content">
          <div v-if="option?.platform" class="universal-select__option__content-icon">
            <a-icon v-if="option.platform.toLowerCase() === 'android'" type="android" class="text-success" />
            <a-icon v-if="option.platform.toLowerCase() === 'ios'" type="apple" class="text-primary" />
            <a-icon v-if="option.platform.toLowerCase() === 'web'" type="global" class="text-default" />
          </div>
          <span>{{ option.name }}</span>
          <div class="universal-select__option__content__suffix">
            <slot name="suffix" :option="option" />
          </div>
        </div>
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
export default {
  name: 'universalSelect',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    highlightFirst: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String],
      default: undefined,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    allowClear: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    displayOptionsAtBottom: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    filteredOptions: [],
  }),
  watch: {
    options: {
      handler(val) {
        this.setAvailableOptions(val)
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setAvailableOptions(options) {
      this.filteredOptions = JSON.parse(JSON.stringify(options))
    },
    getPopupContainer(trigger) {
      if (this.displayOptionsAtBottom) {
        return document.body
      }
      return trigger.parentElement
    },
    handleSearch(searchValue) {
      this.filteredOptions = this.options.filter(option => {
        if (option.name) {
          return option.name.toLowerCase().includes(searchValue)
        }
      })
    },
    handleChange(value) {
      this.$emit('change', value)
      this.setAvailableOptions(this.options)
    },
  },
}
</script>

<style lang="scss">
.universal-select {
  display: flex;
  flex-direction: column;
  &__title {
    font-weight: bold;
  }
  &__option {
    &-highlight {
      font-style: italic !important;
    }
    &__content {
      display: flex;
      gap: 8px;
      &__suffix {
        display: flex;
        flex: 1 1 auto;
        justify-content: flex-end;
        align-items: center;
        margin-right: 10px;
      }
    }
    &__content-icon {
      display: inline;
    }
  }
  .ant-select-selection-selected-value {
    width: 100%;
  }
}
</style>
