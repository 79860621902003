<template>
  <section
    class="main-container"
    :class="{'main-container__block-style' : isBlockStyle}"
  >
    <div v-if="loading" class="main-container__loading">
      <a-icon type="loading" class="main-container__loading__icon" />
    </div>
    <div v-else-if="!loading && (permissionOfSection && !checkPermissions(user.permissions, permissionOfSection, 'view'))" class="main-container__no-access">
      You do not have access to this section
    </div>
    <slot v-else />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import useCheckPermissions from '@/services/useCheckPermissions.js'

export default {
  mixins: [useCheckPermissions],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    permissionOfSection: {
      type: String,
      default: '',
    },
    isBlockStyle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['user']),
  },
}
</script>

<style lang="scss">
.main-container {
  padding: 24px;
  background-color: #fff;
  height: 100%;
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    &__icon {
      font-size: 40px;
      color: #1890ff;
    }
  }
  &__no-access {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 18px;
  }
  &__block-style {
    padding: 0;
    background-color: transparent;
  }
}
</style>
