<template>
  <div>
    <div v-if="show" class="readonly">
      <span
        v-if="title && value"
        class="readonly__title"
        :class="{ 'required' : required }"
      >
        {{ title }}
      </span>
      <div class="readonly__content">
        <slot name="before" />
        <span
          v-if="getValue"
          class="readonly__description"
          :class="{
            'readonly__description__break-all' : breakAll,
            'readonly__description__hide-after' : hideAfter,
          }"
        >
          {{ getValue }}
        </span>
      </div>
    </div>
    <slot v-else />
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    isDate: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String, Object, null],
      default: null,
    },
    breakAll: {
      type: Boolean,
      default: false,
    },
    hideAfter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getValue() {
      if (this.isDate) {
        return this.value.format('YYYY-MM-DD HH:mm:ss')
      }
      return this.value
    },
  },
}
</script>

<style lang="scss">
.readonly {
  display: flex;
  flex-direction: column;
  gap: 14px;
  &__title {
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #262626;
    overflow-wrap: break-word;
  }
  &__content {
    display: flex;
    gap: 5px;
  }
  &__description {
    color: #262626;
    opacity: 0.75;
    font-size: 16px;
    overflow-wrap: break-word;
    white-space: pre-line;
    &__break-all {
      word-break: break-all;
    }
    &__hide-after {
      white-space: pre-wrap;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }
}
</style>
